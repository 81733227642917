import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CategoryIcon from "@mui/icons-material/Category";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Link } from "react-router-dom";
import image from "./../Dawa bazar logo 1 1.png";
import image1 from "./../Dawa bazar logo 1 1 (1).png";

import DescriptionIcon from "@mui/icons-material/Description";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import QuizIcon from "@mui/icons-material/Quiz";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SchemaIcon from '@mui/icons-material/Schema';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import UpdateIcon from '@mui/icons-material/Update';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SavingsIcon from '@mui/icons-material/Savings';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
const Sidebar = () => {
  return (
    <div
      className="sidebar sidbar2 "
      style={{ position: "fixed", padding: "15px" }}
    >
      <div className="top ">
        <Link to="/dashboard">
          {" "}
          <img
            src={image}
            width={40}
            height={35}
            style={{ marginTop: "15px" }}
            alt=""
            className="avatar"
          />
          <img
            src={image1}
            width={150}
            height={30}
            style={{ marginTop: "20px" }}
            alt=""
            className="avatar"
          />{" "}
        </Link>
      </div>

      <div className="center " style={{ marginTop: "20px" }}>
        <ul>
          <Link to="/dashboard" className="text-d-none">
            <li>
              <DashboardIcon className="icon f-16 " />
              <span className="f-16">Dashboard</span>
            </li>
          </Link>

          <Link to="/customers" className="text-d-none">
            <li>
              <PersonOutlineIcon className="icon" />
              <span className="f-16">Customers</span>
            </li>
          </Link>
          <Link to="/order" className="text-d-none">
            <li>
              <CategoryIcon className="icon" />
              <span className="f-16">Order</span>
            </li>
          </Link>
          <Link to="/manufacturer" className="text-d-none">
            <li>
              <BrandingWatermarkIcon className="icon" />
              <span className="f-16">Manufacturer</span>
            </li>
          </Link>
          <Link to="/products" className="text-d-none">
            <li>
              <ProductionQuantityLimitsIcon className="icon" />
              <span className="f-16">Products</span>
            </li>
          </Link>
          <Link to="/purchase-order" className="text-d-none">
            <li>
              <AddShoppingCartIcon className="icon" />
              <span className="f-16">Purchase Order</span>
            </li>
          </Link>
          <Link to="/supplier" className="text-d-none">
            <li>
              <PersonOutlineIcon className="icon" />
              <span className="f-16">Supplier</span>
            </li>
          </Link>

          <Link to="/hsn" className="text-d-none">
            <li>
              <SavedSearchIcon className="icon" />
              <span className="f-16">HSN</span>
            </li>
          </Link>

          <Link to="/coupon-list" className="text-d-none">
            <li>
              <SchemaIcon className="icon" />
              <span className="f-16">Target Scheme List</span>
            </li>
          </Link>

          <p className="title">Stock Management</p>

          <Link to="/stock-report" className="text-d-none">
            <li>
              <ShowChartIcon className="icon" />
              <span className="f-16">Stock Statement</span>
            </li>
          </Link>
          <Link to="/stock-batch" className="text-d-none">
            <li>
              <InsertChartIcon className="icon" />
              <span className="f-16">Batchwise Stock</span>
            </li>
          </Link>

          <Link to="/early-expiry" className="text-d-none">
            <li>
              <TimelapseIcon className="icon" />
              <span className="f-16">Early Expiry</span>
            </li>
          </Link>

          <Link to="/expiry-stock" className="text-d-none">
            <li>
              <HourglassBottomIcon className="icon" />
              <span className="f-16">Expiry Stock</span>
            </li>
          </Link>

          <Link to="/non-moving-stock" className="text-d-none">
            <li>
              <RunningWithErrorsIcon className="icon" />
              <span className="f-16">Non Moving Stock</span>
            </li>
          </Link>

          <Link to="/fast-moving-stock" className="text-d-none">
            <li>
              <UpdateIcon className="icon" />
              <span className="f-16">Fast Moving Stock</span>
            </li>
          </Link>

          <p className="title">Reports</p>

          <Link to="/gst-purchase" className="text-d-none">
            <li>
              <CurrencyExchangeIcon className="icon" />
              <span className="f-16">GST Purchase report</span>
            </li>
          </Link>
          <Link to="/hsn-purchase" className="text-d-none">
            <li>
              <SavedSearchIcon className="icon" />
              <span className="f-16">HSN Purchase report</span>
            </li>
          </Link>
          <Link to="/gst-sale" className="text-d-none">
            <li>
              <ContentPasteSearchIcon className="icon" />
              <span className="f-16">GST Sale report</span>
            </li>
          </Link>
          <Link to="/hsn-sale" className="text-d-none">
            <li>
              <DescriptionIcon className="icon" />
              <span className="f-16">HSN Sale report</span>
            </li>
          </Link>

          <p className="title">BANNER</p>
          <Link to="/deal-of-the-day" className="text-d-none">
            <li>
              <LocalOfferIcon className="icon" />
              <span className="f-16">Deal of the Day</span>
            </li>
          </Link>
          <Link to="/super-saving" className="text-d-none">
            <li>
              <SavingsIcon className="icon" />
              <span className="f-16">Super Saving</span>
            </li>
          </Link>
          <Link to="/new-arrivals" className="text-d-none">
            <li>
              <FiberNewIcon className="icon" />
              <span className="f-16">New Arrivals</span>
            </li>
          </Link>
          <Link to="/advertisement" className="text-d-none">
            <li>
              <CampaignIcon className="icon" />
              <span className="f-16">Advertisement</span>
            </li>
          </Link>
          <p className="title">RETURNS</p>
          <Link to="/order-returns" className="text-d-none">
            <li>
              <SettingsBackupRestoreIcon className="icon" />
              <span className="f-16">Order Return</span>
            </li>
          </Link>
          <Link to="/expiry-returns" className="text-d-none">
            <li>
              <UpdateDisabledIcon className="icon" />
              <span className="f-16">Expiry Return</span>
            </li>
          </Link>
          <p className="title">Others</p>
          <Link to="/FAQ" className="text-d-none">
            <li>
              <QuizIcon className="icon" />
              <span className="f-16">FAQ's</span>
            </li>
          </Link>
          <Link to="/privacy" className="text-d-none">
            <li>
              <PrivacyTipIcon className="icon" />
              <span className="f-16">Privacy</span>
            </li>
          </Link>
          <Link to="/about-us" className="text-d-none">
            <li>
              <InfoIcon className="icon" />
              <span className="f-16">About Us</span>
            </li>
          </Link>

          <Link to="/feedback" className="text-d-none">
            <li>
              <FeedbackIcon className="icon" />
              <span className="f-16">Feedback</span>
            </li>
          </Link>

          <Link to="/ComplainBox" className="text-d-none">
            <li>
              <AllInboxIcon className="icon" />
              <span className="f-16">Complaint Box</span>
            </li>
          </Link>

          <Link to="/help-center" className="text-d-none">
            <li>
              <HelpCenterIcon className="icon" />
              <span className="f-16">Help Center</span>
            </li>
          </Link>

          <Link to="/settings" className="text-d-none">
            <li>
              <SettingsIcon className="icon" />
              <span className="f-16">Settings</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
